import React from 'react';

import Slide1 from 'src/slides/desktop/hu/chapter_1/sub_1/slide1';
import Slide2 from 'src/slides/desktop/hu/chapter_1/sub_1/slide2';
import Slide3 from 'src/slides/desktop/hu/chapter_1/sub_1/slide3';
import Slide4 from 'src/slides/desktop/hu/chapter_1/sub_1/slide4';
import Slide5 from 'src/slides/desktop/hu/chapter_1/sub_1/slide5';
import Slide6 from 'src/slides/desktop/hu/chapter_1/sub_1/slide6';
import Slide7 from 'src/slides/desktop/hu/chapter_1/sub_1/slide7';
import Slide8 from 'src/slides/desktop/hu/chapter_1/sub_1/slide8';
import Slide9 from 'src/slides/desktop/hu/chapter_1/sub_1/slide9';
import Slide10 from 'src/slides/desktop/hu/chapter_1/sub_1/slide10';
import Slide11 from 'src/slides/desktop/hu/chapter_1/sub_1/slide11';

import WrapperMobile from 'src/slides/mobile/hu/chapter_1/sub_1/wrapperMobile';

import { isMobile } from 'react-device-detect';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider';
import SEO from '../../../components/_shared/seo';

// desktop

const allSlides = [
  <Slide1 />,
  <Slide2 />,
  <Slide3 />,
  <Slide4 />,
  <Slide5 />,
  <Slide6 />,
  <Slide7 />,
  <Slide8 />,
  <Slide9 />,
  <Slide10 />,
  <Slide11 />,
];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO
      title="Hogyan változtatta meg a világot az I. világháború | A varsói csata"
      description="A régi világrend bukása és a modern világ hajnala"
      lang="hu"
    />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

// mobile

const Mobile = () => (
  <>
    <SEO
      title="Hogyan változtatta meg a világot az I. világháború | A varsói csata"
      description="A régi világrend bukása és a modern világ hajnala"
      lang="hu"
    />
    <WrapperMobile />
  </>
);

// export

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
